import { connect } from 'react-redux';
import App from '../../Components/App';
import {
  handleAuthSession, authorize, exchangeCodeForTokens, syncLanguageConfig
} from '../../reducers/auth';
import { getAppConfig } from '../../reducers/appConfig';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  appConfig: state.appConfig,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  handleAuthSession(...args) {
    return handleAuthSession(dispatch, ...args);
  },
  syncLanguageConfig() {
    return syncLanguageConfig(dispatch);
  },
  authorize(...args) {
    return authorize(dispatch, ...args);
  },
  exchangeCodeForTokens(...args) {
    return exchangeCodeForTokens(dispatch, ...args);
  },
  getAppConfig(...args) {
    return getAppConfig(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
