import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Spin } from 'antd';
import {
  MaintenanceMode, Exception, getCookieLangFromID, getCookieKeyForLocale
} from 'componentlibrary';

import AuthModal from '../../Containers/AuthModal';
import { routes } from '../../routes';
import { loader } from '../../sass/modules/loader.module.scss';
import ResponsiveLayout from '../../Containers/ResponsiveLayout';
import ability from '../Can/ability';
import { buildCrmResourcesMenuItems } from './buildMenuItems';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      currentLanguage: 'en',
    };
  }

  componentDidMount() {
    const { appConfig } = this.props;
    if (appConfig && appConfig.maintenanceMode === false) {
      this.handleAuth();
    }

    this.getAppConfig();
  }

  componentDidUpdate(prevProps) {
    const {
      auth, appConfig, i18n, syncLanguageConfig
    } = this.props;
    const { showError, currentLanguage } = this.state;
    const { idToken } = auth;

    // Only call handleAuth() if the application loads with maintenanceMode
    // turned off or if it was previously on and now it's been turned off.
    if (
      (prevProps.appConfig
        && prevProps.appConfig.maintenanceMode === true
        && appConfig.maintenanceMode === false)
      || (prevProps.appConfig === null && appConfig && appConfig.maintenanceMode === false)
    ) {
      this.handleAuth();
    }
    if (prevProps && prevProps.i18n && prevProps.i18n.language !== currentLanguage) {
      this.setState({ currentLanguage: prevProps.i18n.language }, () => {
        syncLanguageConfig();
      });
    }

    const username = idToken && idToken['cognito:username'] ? idToken['cognito:username'] : '';
    const cookieLanguage = getCookieLangFromID(getCookieKeyForLocale(username));
    if (prevProps
      && prevProps.i18n
      && cookieLanguage
      && prevProps.i18n.language !== cookieLanguage) {
      const currentLocale = cookieLanguage;
      i18n.changeLanguage(currentLocale);
    }

    if (auth.error && !showError) {
      this.handleAuthError(auth.error);
    }
  }

  async handleAuth() {
    const { handleAuthSession, exchangeCodeForTokens } = this.props;

    const code = this.getSearchParam('code');
    const state = this.getSearchParam('state');
    const iframeUrl = this.getSearchParam('iframe_url');
    if (iframeUrl && iframeUrl.startsWith('http')) localStorage.setItem('iframe_url', iframeUrl);
    // We are not currently going through the authorization flow.
    // Call handleAuthSession to use an existing session or begin a new one.
    if (!code) {
      return handleAuthSession();
    }

    // Auth code flow in progress. Invoke token exchange
    await exchangeCodeForTokens(code, state);

    return this.redirect();
  }

  handleAuthError() {
    this.setState({
      showError: true,
    });
  }

  get loaderMessage() {
    const { auth } = this.props;

    if (auth.authorizing) {
      return 'redirecting';
    }

    if (this.getSearchParam('code') !== null) {
      return 'authorizing';
    }

    return null;
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  getSearchParam(param) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.has(param)) {
      return params.get(param);
    }

    return null;
  }

  redirect() {
    const { history } = this.props;
    const pathname = sessionStorage.getItem('lastPath') || '/';
    const url = new URL(pathname, window.location.origin);
    const lastIframeFromTokenExchange = sessionStorage.getItem('lastIframeParam') || '';
    sessionStorage.removeItem('lastPath');
    sessionStorage.removeItem('lastIframeParam');

    if (lastIframeFromTokenExchange && lastIframeFromTokenExchange.startsWith('http')) {
      url.searchParams.set('iframe_url', lastIframeFromTokenExchange);
    }

    const lastIframeFromLogin = localStorage.getItem('iframe_url');
    localStorage.removeItem('iframe_url');

    if (lastIframeFromLogin && lastIframeFromLogin.startsWith('http')) {
      url.searchParams.set('iframe_url', lastIframeFromLogin);
    }

    history.push(`${url.pathname}${url.search}`);
  }

  render() {
    const { showError } = this.state;
    const {
      t, appConfig, auth = {}, authorize
    } = this.props;

    if (!appConfig) {
      return null;
    }

    const { error, accessToken } = auth;

    if (this.loaderMessage) {
      return (
        <Spin className={loader} size="large" tip={t(this.loaderMessage)}>
          <div />
        </Spin>
      );
    }

    if (error && showError) {
      return (
        <Exception status={error.status} message={t(`errors:${error.status.toString()}`)}>
          <Button onClick={authorize}>{t('errors:tryAgain')}</Button>
        </Exception>
      );
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:systemDown')}
          description={t('maintenanceMode:pleaseLogout')}
        />
      );
    }

    if (!accessToken) {
      return <AuthModal />;
    }

    const menuProps = [];

    menuProps.push({
      key: 'home',
      title: t('sideMenu:homepage'),
      icon: <Icon type="appstore" />,
      path: '/id-homepage'
    });

    const crmResourcesItem = buildCrmResourcesMenuItems(ability, t);
    if (ability.can('view', 'CRMResourcesMenu')) {
      menuProps.push({
        key: 'crmResources',
        title: t('sideMenu:crmResources'),
        icon: <Icon type="appstore" />,
        children: crmResourcesItem,
      });
    }

    return (
      <ResponsiveLayout
        t={t}
        menuProps={menuProps}
        supportMenuEnabled={appConfig.enableSupportMenu}
      >
        {renderRoutes(routes)}
        <AuthModal />
      </ResponsiveLayout>
    );
  }
}

App.defaultProps = {
  auth: {},
  appConfig: null,
};

App.propTypes = {
  auth: PropTypes.object,
  handleAuthSession: PropTypes.func.isRequired,
  authorize: PropTypes.func.isRequired,
  exchangeCodeForTokens: PropTypes.func.isRequired,
  syncLanguageConfig: PropTypes.func.isRequired,
  appConfig: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(withRouter(App));
